import styled from '@emotion/styled';
import { BiSolidErrorAlt } from 'react-icons/bi';

const Container = styled.div`
	margin: 24px 0;
	display: flex;
	gap: 12px;
	align-items: flex-start;
`;

const Content = styled.div``;

const IconContainer = styled.div`
	& svg {
		color: #f33333;
		font-size: 48px;		
	}
`;

const H3 = styled.h3`
	font-size: 22px;
	margin-bottom: 12px;
	margin-top: 0;
`;

const Label = styled.div`
	font-size: 18px;
	font-weight: 500;
`;

const Error = () => {
	return (
		<Container>
			<IconContainer>
				<BiSolidErrorAlt />
			</IconContainer>
			<Content>
				<H3>There was an error</H3>
				<Label>Please <a href='https://www.hitmixbingo.com/pages/contact-hitmix-bingo' target='_blank'>contact us</a> and let us know. We'll get this fixed up as soon as we can.</Label>
			</Content>
		</Container>
	)
}

export default Error;