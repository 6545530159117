import { useMemo } from 'react';
import Select from 'react-select'
import { Controller } from 'react-hook-form';
import countryList from 'react-select-country-list'

const CountrySelector = ({ ref, value, onChange, filterCountries = false }) => {
	let options = useMemo(() => countryList().getData(), [])
	
	if(filterCountries){
		options = options.filter(option => filterCountries.includes(option.value))
	}
	
	  return (
		  <Select
			  inputRef={ref || null}
			  styles={{
				  control: (baseStyles, state) => ({
					  ...baseStyles,
					  maxWidth: '415px',
					  border: '2px solid black',
					  borderRadius: '0',
					  padding: '2px 6px',
					  fontSize: '1.25rem',
					  color: 'black !important',
					  margin: '8px 0 24px 0'
				  }),
				  menu: (baseStyles, state) => ({
					  ...baseStyles,
					  color: 'black'
				  }),
				  indicatorSeparator: () => ({ display: 'none' }),
			  }}
			options={options}
			value={value || null}
			onChange={val => {
				if(onChange) onChange(val.value)
			}} 
		  />
	  )
}

export default CountrySelector;