import styled from '@emotion/styled';
import HostCardOptions from './HostCardOptions';
import ReactCountryFlag from 'react-country-flag';
import LocationCountyName from './LocationCountyName';
import LocationStateName from './LocationStateName';

const Container = styled.div`
	padding: 16px;
	background: white;
	border: 3px solid #cba3d6;
	border-radius: 0px;
	box-sizing: content-box;
	grid-column-end: span 1;
`;

const HostName = styled.div`
	font-size: 1.5rem;
	font-weight: 600;
	color: #6a1177;
	margin-bottom: .2rem;
`;

const CompanyName = styled.div`
	font-size: 1.2rem;
	font-weight: 500;
	color: #ca6189;
	
`;

const LocationPinIcon = styled.img`
	width: 32px;
	height: 32px;
`;

const LocationHStack = styled.div`
	display: flex;
	align-items: center;
	margin: .75rem 0;
	gap: .5rem;
`;

const LocationVStack = styled.div`
	display: flex;
	flex-direction: column;
`;

const LocationCityName = styled.div`
	font-weight: 600;
	font-size: 1.1rem;
	color: #3d3d3d;
`;

const LocationPostcode = styled.div`
	font-weight: 600;
	font-size: .9rem;
	color: #717171;
`;

const LocationGMaps = styled.a``;

const Host = ({ full_name, company_name, city, country, county, state, postal_code, lat, lon, email, contact_telephone, website_url, distance = null }) => {
	return (
		<Container>
			<HostName>{full_name}</HostName>
			<CompanyName>{company_name}</CompanyName>
			
			<LocationHStack>
				<LocationPinIcon src={'https://directory.hitmixbingo.com/location-pin-icon.svg'} />
				<LocationVStack>
					<LocationCityName><ReactCountryFlag
						countryCode={country}
						svg={true}
					/> {city}</LocationCityName>
					
					{county && 
						<LocationCountyName>
							{county}
						</LocationCountyName>
					}
					
					{state && 
						<LocationStateName>{state}</LocationStateName>
					}
					
					<LocationPostcode>{postal_code}{distance !== null ? ' | ' + Math.floor(distance) + ' miles away' : ''}</LocationPostcode>
				</LocationVStack>
			</LocationHStack>
			
			<HostCardOptions
				email={email}
				contact_telephone={contact_telephone}
				website_url={website_url}
				lat={lat} lon={lon}
			/>
			
		</Container>
	)
}

export default Host;