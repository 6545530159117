import styled from '@emotion/styled';
import { PiSidebarSimpleBold } from 'react-icons/pi';

const Container = styled.div`
	position: fixed;
	display: flex;
	align-items: center; justify-content: center;
	
	font-size: 24px;
	color: white;
	cursor: pointer;
	
	min-width: 48px; min-height: 48px;
	max-width: 48px; max-height: 48px;
	
	top: 16px; left: 16px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
	
	background-color: #bd69c9;
	border-radius: 128px;
	z-index: 2;
	
	@media (min-width: 850px) {
		display: none;
	}
`;

const SidebarExpando = ({ onClick }) => {
	return (
		<Container onClick={onClick}>
			<PiSidebarSimpleBold />	
		</Container>
	)
}

export default SidebarExpando;