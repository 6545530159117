import styled from '@emotion/styled';
import HitmixBingoLogo from './HitmixBingoLogo';

const Container = styled.header`
	background: linear-gradient(180deg, #60113B 0%, #6F1290 100%);
	border-bottom: 5px solid;
	position: relative;
	width: 100%;
	min-width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	
	& h2 {
		color: #f5d863;
		font-family: Bangers,sans-serif;
		font-size: 4rem;
		text-shadow: 3px 4px 0 #000000d9;
	}
	
	&::before {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-position: center center;
		background-repeat: no-repeat;
		background-image: url(https://bouncycastlenetwork-res.cloudinary.com/image/upload/Sites/hitmix-bingo/header-shine.svg);
		background-size: auto 100%;
		background-repeat: repeat-x;
	}
	
	@media ( max-width: 850px ) {
		flex-direction: column;
		
		& h2 {
			margin: 0 0 1rem 0;
			font-size: 2.8rem;	
		}	
	}
	
	@media ( max-width: 500px ) {
		& h2 {
			font-size: 2rem
		}
	}
`;

export default ({ subtitle }) => {
	return (
		<Container>
			<HitmixBingoLogo />
			{subtitle && <h2>{subtitle}</h2>}
		</Container>
	)
}