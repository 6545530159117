import styled from '@emotion/styled';

const Content = styled.div`
	font-weight: 600;
	font-size: 1rem;
	color: #6a6a6a;
`;

export default ({children}) => {
	return (
		<Content>{children}</Content>
	)
}