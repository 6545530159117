import { useEffect } from 'react';
import { useContext } from 'react';
import styled from '@emotion/styled';

import GlobalStateContext from '../contexts/GlobalStateContext';
import GeolocationPrompt from './GeolocationPrompt';

import { MdOutlineMyLocation } from "react-icons/md";
import { MdOutlineLocationDisabled } from "react-icons/md";
import { FaRegHourglass } from "react-icons/fa";

import Loading from './Loading';

const Button = styled.button`
	background: white;
	border: 2px solid black;
	color: black;
	font-size: 22px;
	line-height: 0 !important;
	
	&.has-location {
		background-color: #F5D863
	}
	
`;

export default () => {
	const {
		browserSupportsGeolocation,
		browserGrantedGeolocation,
		isRequestingGeolocation,
		requestGeolocation,
		userLocation,
		mileRange, setMileRange,
		resetGeolocation
	} = useContext(GlobalStateContext);
	
	const MAX_RANGE_VALUE = 120;
	
	if(!browserSupportsGeolocation) return null;
	if(browserGrantedGeolocation === false) return null;
	
	const handleRangeChange = evt => {
		const value = Math.round(evt.currentTarget.value);
		if(value == MAX_RANGE_VALUE){
			setMileRange(null);
		} else {
			setMileRange(value);	
		}
	}
	
	const defaultValue = () => {
		if(!mileRange) return MAX_RANGE_VALUE;
		return mileRange;
	}
	
	useEffect(() => {
		if(browserGrantedGeolocation && !userLocation){
			requestGeolocation();
		}
	}, [ browserGrantedGeolocation ]);
	
	function clicked(){
		if(userLocation){
			resetGeolocation();
		} else {
			requestGeolocation();	
		}
	}
	
	return (
		<Button
		className={
			userLocation && 'has-location'
		}
		onClick={
			clicked
		}
			disabled={
			   !browserSupportsGeolocation ||
			   browserGrantedGeolocation === false || isRequestingGeolocation
			}
		>
			{
				(!browserSupportsGeolocation || browserGrantedGeolocation === false) && <MdOutlineLocationDisabled />
			}
			{
				browserSupportsGeolocation && !isRequestingGeolocation && <MdOutlineMyLocation />
			}
			{ isRequestingGeolocation && <FaRegHourglass /> }
		</Button>
	)
}