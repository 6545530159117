import styled from '@emotion/styled';
import { TiLocationArrowOutline } from 'react-icons/ti';

import GlobalStateContext from '../contexts/GlobalStateContext';
import { useContext, useState } from 'react';

const Container = styled.div`
	margin: 16px 0;
	background-color: #9b58b5;
	color: white;
	border-radius: 24px;
	padding: 16px;
`;
const Content = styled.div`
	& p {
		margin: 0;
	}
	
	& p.emph {
		font-size: 1.1rem;
		font-weight: 600;
		margin-bottom: .5rem;	
	}
	
	& p.small {
		color: white
		margin-bottom: .5rem;
	}
`;

const IconContainer = styled.div`
	font-size: 36px;
`;

const TextButton = styled.button`
	background: none;
	padding: 0;
	color: #F5D863;
	font-weight: 600;
	text-decoration: underline;
	margin-top: 8px;
`;

export default ({standalone = false}) => {
	const { requestGeolocation, isRequestingGeolocation, setIsRequestingGeolocation } = useContext(GlobalStateContext);

	const [ didDismissGeolocationPrompt, setDidDismissGeolocationPrompt ] = useState(false);
	
	const dismissGeolocationPrompt = () => {
		setDidDismissGeolocationPrompt(true);
	}

	return !didDismissGeolocationPrompt && (
		<Container className={standalone ? 'standalone' : 'in-sidebar'}>
			<IconContainer>
				<TiLocationArrowOutline />			
			</IconContainer>
			
			<Content>
				<p class="emph">Share access to your location to see Hitmix Bingo hosts near you.</p>
				<p class="small">This will unlock enhanced features:</p>
				<ul>
					<li>See hosts closest to you</li>
					<li>Filter the list of hosts by their distance to you</li> 
				</ul>
				<p class="small"><strong>Hitmix Bingo never has access to your location, and it is never stored on our servers.</strong></p>
				{!isRequestingGeolocation && <TextButton onClick={requestGeolocation}>Share Location</TextButton>}
				&nbsp;&nbsp;&nbsp;&nbsp;
				<TextButton onClick={dismissGeolocationPrompt}>No thanks</TextButton>
			</Content>
		</Container>
	)
}