import React, { useContext, useEffect, useState, useRef } from 'react';
import styled from '@emotion/styled';

import Host from './Host';
import { Masonry } from 'react-masonry'
import EndOfResults from './EndOfResults';
import Loading from './Loading';
import Error from './Error';

import GlobalStateContext from '../contexts/GlobalStateContext';

import { useInfiniteQuery } from '@tanstack/react-query';
import useInfiniteScroll from 'react-infinite-scroll-hook';

const useDebounce = (value, delay) => {
	const [debouncedValue, setDebouncedValue] = useState(value);

	useEffect(
		() => {
			// Set debouncedValue to value (passed in) after the specified delay
			const handler = setTimeout(() => {
				setDebouncedValue(value);
			}, delay);

			// Return a cleanup function that will be called every time 
			// useEffect is re-called. useEffect will only be re-called 
			// if value changes (see the inputs array below). 
			// This is how we prevent debouncedValue from changing if value is 
			// changed within the delay period. Timeout gets cleared and restarted.
			return () => {
				clearTimeout(handler);
			};
		},
		// Only re-call effect if value changes
		[value, delay] // Only re-call effect if value or delay changes
	);

	return debouncedValue;
};


const HostsGrid = styled.div`
	display: grid;
	grid-template-columns: repeat( auto-fit, minmax(250px, 1fr) );
	gap: 16px;
	&::before {
		width: 280px;
	}
`;


const Hosts = () => {
	const {
		setFilterOptions,
		filterCountry, mileRange, searchQuery,
		lat, lon
	} = useContext(GlobalStateContext);
	
	const [ shouldFetchNext, setShouldFetchNext ] = useState(null);
	
	const fetchHosts = async ({ pageParam = 1, signal }) => {
		const queryParams = {
			page: pageParam,
			perPage: 30
		}
		
		if(searchQuery) queryParams.q = searchQuery;
		if(filterCountry) queryParams.country = filterCountry.value;
		if(lat) {
			queryParams.lat = lat;
			queryParams.lon = lon;
		}
		
		if(queryParams.lat && mileRange) queryParams.miles = mileRange;
		
		const searchParams = new URLSearchParams(queryParams).toString();
		
		const res = await fetch('https://purchase.hitmixbingo.com/api/hosts?' + searchParams, { signal });
		const json = await res.json();
		
		if(json?.filter_options){
			setFilterOptions(json.filter_options);
		}
	
		return json;
	}
	
	const {
		data,
		error,
		fetchNextPage,
		hasNextPage,
		isFetching,
		isFetchingNextPage,
		status,
	  } = useInfiniteQuery({
		queryKey: [
			'hosts',
			filterCountry, mileRange, searchQuery,
			lat, lon
		],
		queryFn: fetchHosts,
		getNextPageParam: (lastPage, pages) => lastPage.next_page,
	  })
	  
	  const [ sentryRef ] = useInfiniteScroll({
		  loading: isFetching,
		  hasNextPage: hasNextPage,
		  onLoadMore: fetchNextPage,
		  disabled: !!error,
		  rootMargin: '400px'
	  });
	
	  return status === 'loading' ? (
		<Loading label={"Loading..."} />
	  ) : status === 'error' ? (
		<Error />
	  ) : (
		<>
		  <HostsGrid>
		  {data.pages.map((group, i) => (
			<React.Fragment key={i}>
		  	{group.data.map((host) => (
				<Host {...host} />
		  	))}
			</React.Fragment>
		  ))}
		  <div id="sentry" ref={sentryRef} />
		  </HostsGrid>
		  <div>
		  
		  	<div>{isFetching && !isFetchingNextPage ? <Loading label={"Loading more..."} /> : null}</div>
		  				  
		    {!hasNextPage && <EndOfResults />}
			
		  </div>
		</>
	)
}

export default Hosts;