import React, { useState, useEffect } from 'react';
import GlobalStateContext from './GlobalStateContext';
import countryList from 'react-select-country-list'

const GlobalStateProvider = ({ children }) => {

  const [ loading, setLoading ] = useState(true);

  const [ userCountry, setUserCountry ] = useState(null);  
  const [ userLocation, setUserLocation ] = useState(null);
  
  const [ lat, setLat ] = useState(null);
  const [ lon, setLon ] = useState(null);
  const [ mileRange, setMileRange ] = useState(null);

  const [ filterOptions, setFilterOptions ] = useState(null);
  const [ filterCountry, setFilterCountry ] = useState(null);
  const [ searchQuery, setSearchQuery ] = useState(null);

  const [ browserSupportsGeolocation, setBrowserSupportsGeolocation ] = useState(null);
  const [ browserGrantedGeolocation, setBrowserGrantedGeolocation ] = useState(null);
  
  const [ isRequestingGeolocation, setIsRequestingGeolocation ] = useState(false);
  
  const resetGeolocation = () => {
	setUserLocation(null);
	setLat(null);
	setLon(null);	  
  }
  
  const requestGeolocation = () => {
	  if(!browserSupportsGeolocation){
		  setBrowserGrantedGeolocation(false);
		  return;
	  }
	  
	  setIsRequestingGeolocation(true);
	  
	  navigator.geolocation.getCurrentPosition(
		  (geolocationPosition) => {
	  	  	setBrowserGrantedGeolocation(true);
		  	setUserLocation(geolocationPosition);
			if(!lat){
				setLat(geolocationPosition?.coords?.latitude);
				setLon(geolocationPosition?.coords?.longitude);
			}
		  	setIsRequestingGeolocation(false);
	  	  },
		
		  () => {
			setBrowserGrantedGeolocation(false);
			setIsRequestingGeolocation(false);
		  }
		);
  }
  
  useEffect(() => {
	/*	On application start, try to get the user's current country.
	    If we can't, assume the user's current country is GB. */
		
		(async() => {
			try {
				const response = await fetch('https://purchase.hitmixbingo.com/determine_currency.php');
				const json = await response.json();
				if(json?.data?.country_code){
					setUserCountry(json.data.country_code);
					
					let [ filterCountry ] = countryList().getData().filter(country => country.value == json.data.country_code);
					setFilterCountry(filterCountry);
					console.log({filterCountry});
					
					setLoading(false);
				} else {
					throw new Error('Could not get country code from determine_currency response');
				}
			} catch ( err ) {
				setUserCountry('GB');
				
				let [ filterCountry ] = countryList().getData().filter(country => country.value == 'GB');
				setFilterCountry(filterCountry);
				
				setLoading(false);
			}
			
		})();
  }, []);
  
  useEffect(() => {
	 (async () => {
 		if(navigator.geolocation){
		  	setBrowserSupportsGeolocation(true);
	  	} else {
		  	setBrowserSupportsGeolocation(false);
	  	}
	  	
	  	try {
		  if(navigator?.permissions?.query){
			const { state } = await navigator.permissions.query({ name: 'geolocation' });
		  	if(state == 'granted'){
			  	setBrowserGrantedGeolocation(true);
		  	} else if(state == 'denied'){
			  	setBrowserGrantedGeolocation(false);
		  	}	  
		  }
	  	} catch(err){
		  	
	  	}
	 })();
  }, []);
  

  return (
	<GlobalStateContext.Provider value={{
		loading,
		userCountry, setUserCountry,
		userLocation, setUserLocation,
		lat, lon, setLat, setLon, 
		mileRange, setMileRange,
		searchQuery, setSearchQuery,
		filterOptions, setFilterOptions,
		filterCountry, setFilterCountry,
		browserSupportsGeolocation, setBrowserSupportsGeolocation,
		browserGrantedGeolocation, setBrowserGrantedGeolocation,
		requestGeolocation, isRequestingGeolocation, setIsRequestingGeolocation,
		resetGeolocation
	}}>
	  {children}
	</GlobalStateContext.Provider>
  );
};

export default GlobalStateProvider;
