import styled from '@emotion/styled';
import { Tooltip } from 'react-tippy';
import { PiGlobeHemisphereWestBold, PiPhoneBold, PiEnvelopeSimpleBold } from 'react-icons/pi'
import { TbDirectionSign } from 'react-icons/tb';

const Container = styled.div`
	font-size: 28px;
	
	&.disabled {
		color: rgba(0,0,0,.3);
	}
`;

const A = styled.a`
	color: black;
`;

const HostCardOption = ({ method, content, href, disabled, target='' }) => {
	let methodName;
	let Icon;
	switch(method){
		case "phone":
			Icon = PiPhoneBold;
			methodName = "Phone";
			break;
		case "email":
			Icon = PiEnvelopeSimpleBold;
			methodName = "Email";
			break;
		case "website":
			Icon = PiGlobeHemisphereWestBold;
			methodName = 'Website';
			break;
		case "maps":
			Icon = TbDirectionSign;
			methodName = "Open in Google Maps";
			break;
		}
	
	return (
		<Tooltip
			title={content}
			position={'bottom'}
			trigger={'mouseenter focus'}
		>
			<A href={disabled ? '#' : href} target={disabled ? null : target}>
				<Container className={disabled ? 'disabled' : ''}>
					<Icon />
				</Container>
			</A>
		</Tooltip>
	)
}

export default HostCardOption;