import styled from '@emotion/styled';

const Img = styled.img`
	width: 100%;
	margin: 32px;
	max-width: 376px;
	
	@media ( max-width: 850px ) {
		margin: 16px
	}
	
	@media ( max-width: 500px ) {
		width: 75%;	
	}
		
`;

export default () => {
	return (
		<a href={'//hitmixbingo.com'}>
			<Img
				src={'https://bouncycastlenetwork-res.cloudinary.com/image/upload/f_auto/Sites/hitmix-bingo/hitmix-bingo-logo@2x.png'}
			/>
		</a>
	)
}