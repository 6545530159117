import styled from '@emotion/styled';
import HostCardOption from './HostCardOption';
import { AsYouType } from 'libphonenumber-js';

const HostCardOptions = styled.div`
	display: flex;
	gap: 8px;
	margin-top: 1rem;
	justify-content: space-around;
`;

export default ({
	contact_telephone = null,
	email = null,
	website_url = null,
	lat = null,
	lon = null,
}) => {
	function makeFullyQualifiedURL(url) {
	  // Check if the URL already has a protocol
	  if (url.startsWith("http://") || url.startsWith("https://")) {
		return url;
	  }
	  
	  // Add 'https://' protocol to the URL if it doesn't have any
	  return "https://" + url;
	}
	
	const formattedPhoneNumber = new AsYouType().input('+' + contact_telephone, 'US');
		
	return (
		<HostCardOptions>

		<HostCardOption
			method="phone"
			disabled={!contact_telephone}
			content={formattedPhoneNumber || "No phone number"}
			href={contact_telephone ? `tel:+${contact_telephone}` : null}
		/>
		
		<HostCardOption
			method="email"
			disabled={!email}
			content={email || null}
			href={email ? `mailto:${email}` : "No email address"}	
		/>
		
		<HostCardOption
			method="website"
			disabled={!website_url}
			content={website_url || "No website"}
			href={website_url ? makeFullyQualifiedURL(website_url) : null}
			target={'_blank'}	
		/>
		

		<HostCardOption
			method="maps"
			disabled={!lat}
			content={lat ? 'Show in Google Maps' : ' No location provided'}
			target={lat ? '_blank' : null}
			href={lat ? `https://www.google.com/maps?z=12&t=m&q=loc:${lat}+${lon}` : null}
		/>
		</HostCardOptions>
	)
}