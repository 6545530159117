import styled from '@emotion/styled';
import { useContext, useState } from 'react';

import Geolocation from './Geolocation';
import { SearchBox } from '@mapbox/search-js-react';

import GlobalStateContext from '../contexts/GlobalStateContext';

const HStack = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 8px;
`;

const Input = styled.input`

  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 128px;  
  background-color: rgba(100,100,100, 0.4);
  outline: none;
  -webkit-transition: .2s;
  transition: opacity .2s;
  margin: 8px 0;

&::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 32px;
  height: 32px;
  border-radius: 50%; 
  background: #FF4A71;
  box-shadow: 0 0 6px rgba(0,0,4px,.4);
  cursor: pointer;
}

&::-moz-range-thumb {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #FF4A71;
  box-shadow: 0 0 6px rgba(0,0,4px,.4);
  cursor: pointer;
}

`;

export default () => {
	const {
		browserSupportsGeolocation,
		browserGrantedGeolocation,
		requestGeolocation,
		userLocation,
		mileRange, setMileRange,
		lat, lon, setLat, setLon,
		setUserLocation,
		
	} = useContext(GlobalStateContext);
	
	const MAX_RANGE_VALUE = 120;
	
	const handleRangeChange = evt => {
		const value = Math.round(evt.currentTarget.value);
		if(value == MAX_RANGE_VALUE){
			setMileRange(null);
		} else {
			setMileRange(value);	
		}
	}
	
	const defaultValue = () => {
		if(!mileRange) return MAX_RANGE_VALUE;
		return mileRange;
	}

	const onRetrieve = (v) => {
		/* get lat+lon from retrieval, if possible */
		const [ lon, lat ] = v?.features?.[0]?.geometry?.coordinates;
		setLat(lat); setLon(lon);
		setUserLocation(null);
	}
	
	const [ overrideValue, setOverrideValue ] = useState('');

	return (
		<div>
			
			<HStack>
				<div>
					<SearchBox
						accessToken={'pk.eyJ1IjoiZmFpenp6IiwiYSI6ImNrdDIzZHhwZTBnaHcydXFweWo0eDRjemgifQ.usmEg0RweO7ww7rLwKAPEQ'}
						value={overrideValue}
						onRetrieve={onRetrieve}
					/>
					<div>
					
					</div>
				</div>
						
				<Geolocation />	
				
			</HStack>
			
			{lat && 
			<div style={{marginTop: 8}}>
				<Input
					type={'range'}
					min={1}
					max={MAX_RANGE_VALUE} 
					step={1}
					defaultValue={defaultValue()}
					onChange={handleRangeChange}
				/>
				<h4>within {defaultValue()}{defaultValue() == MAX_RANGE_VALUE ? '+' : ''} mile{mileRange == 1 ? '' : 's'}</h4>
			</div> }
			
		</div>
	)
}

