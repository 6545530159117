import styled from '@emotion/styled';

const Container = styled.div`
	display: flex;
	align-items: center;
	gap: 12px;
	margin: 16px 0;
`;

const Spinner = styled.div`
	width: 36px !important;
	height: 36px !important;
`;

const Label = styled.div`
	font-size: 18px;
	font-weight: 600;
`;

const Loading = ({label}) => {
	return (
		<Container>
			<Spinner className="loading" />
			<Label>{label}</Label>
		</Container>
	)
}

export default Loading;