import React, { useContext, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

/* stylesheets */
import 'react-tippy/dist/tippy.css'
import './App.css';
import './hitmix.css';


/* components */
import HostDirectoryPage from './components/HostDirectoryPage';

/* contexts */
import GlobalStateContext from './contexts/GlobalStateContext';
import GlobalStateProvider from './contexts/GlobalStateProvider';

/* new react-query client */
const queryClient = new QueryClient();

function App() {
  useEffect(() => {
    document.title = "Hitmix Bingo | Host Directory";
  }, []);
  
  return (
    <QueryClientProvider client={queryClient}>
      <GlobalStateProvider>
        <AppContent />
      </GlobalStateProvider>
    </QueryClientProvider>
  );
}

const AppContent = () => {
  const { loading } = useContext(GlobalStateContext);  
  
  if(loading){
    return ( 
      <div class="loading-container">
        <div class="loading"></div>
      </div>
    )
  }
  
  return (
    <main>
      <HostDirectoryPage />
    </main>
  )
}

export default App;
